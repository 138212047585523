<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0"> {{
              $t("message.new_m", {
                m: $t("message.payment"),
              })
            }}
            <span class="ml-5"><b> {{$t('message.client_balance')}}: {{ parseFloat(selectedClient.balance ? parseFloat(selectedClient.balance) : 0) | formatMoney(2) }}</b></span>
            </p>
          <div>
             <crm-store-update-close
                :permission="$options.name"
                :button_type="'store'"
                :loading="loadingButton"
                @c-submit="beforeSubmit(true)"
                @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0 mt-5">
        <div class="timeline-items__right rounded-sm w-100 p-4">
            <el-form ref="form" :model="form" :rules="rules" label-position="top">
                <el-row :gutter="20">
                    <el-col :span="8">
                      <el-form-item
                      prop="client_id"
                      :label="$t('message.client')"
                      >
                        <select-client
                            :size="'mini'"
                            @getClient="getClient"
                            :placeholder="columns.client_id.title"
                            :id="form.client_id"
                            v-model="form.client_id"
                            >
                        </select-client>
                      </el-form-item> 
                    </el-col>
                    <el-col :span="8" v-if="form.client_id">
                      <el-form-item
                        prop="money_amount"
                        :label="$t('message.money_amount')"
                        >
                          <el-input
                            :placeholder="$t('message.money_amount')"
                            :size="'medium'"
                            v-model="form.money_amount"
                            type="number"
                            >
                          </el-input>
                      </el-form-item>  
                    </el-col>
                  </el-row>
            </el-form>
        </div>
      </div>
      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4">
            <div>
              <el-button :client_id="form.client_id" @click="checkClient()" size="large" type="primary" plain  v-loading="loadingUnpaidDeals">{{$t('message.unpaidClientDeals')}}</el-button>
              <span class="ml-5"><b> {{$t('message.total_amount')}}: {{ total_amount | formatMoney(2) }}</b></span>
              <span class="ml-5"><b> {{$t('message.paid_money')}}: {{ total_paid | formatMoney(2) }}</b></span>
              <span class="ml-5" :class="(total_paying > form.money_amount) ? 'danger-insufficient' : ''"><b> {{$t('message.paying_money')}}: {{ total_paying*form.currency_rate | formatMoney(2) }}</b></span>
              <span class="ml-5"><b> {{$t('message.remainder')}}: {{ (total_remainder>0 ? total_remainder : 0)| formatMoney(2) }}</b></span>
              <span class="ml-5"><b> {{$t('message.remainder_for_client_balance')}}: {{ (remainderForClientBalance>0 ? remainderForClientBalance : 0)  | formatMoney(2) }}</b></span>
            </div>
            <el-row v-loading="loadingUnpaidDeals">
              <el-col :span="24">
                <el-table
                ref="multipleTable"
                :data="selectedDealsList"
                size="small"
                :row-class-name="tableRowClassName"
                @selection-change="handleSelectionChange"
                @select="dealSelected"
                style="width: 100%">
                    <el-table-column
                        type="selection"
                        width="55">
                    </el-table-column>
                    <el-table-column
                        width="60"
                        :label="$t('message.n')">
                        <template slot-scope="item">
                            {{ item.row.id }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        :label="$t('message.end_date')">
                        <template slot-scope="item">
                            {{ item.row.end_date }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        :label="$t('message.from_filial')">
                        <template slot-scope="item">
                            {{ item.row.from_filial }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        :label="$t('message.to_filial')">
                        <template slot-scope="item">
                            {{ item.row.to_filial }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        :label="$t('message.total_amount')">
                        <template slot-scope="item">
                            {{ item.row.money_amount | formatMoney(2) }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        :label="$t('message.incoming')">
                        <template slot-scope="item">
                            {{ item.row.incoming_money | formatMoney(2) }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        :label="$t('message.paid_money')">
                        <template slot-scope="item">
                            {{ item.row.paid_money | formatMoney(2) }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        width="180"
                        :label="$t('message.paying_money')">
                        <template slot-scope="item">
                          <el-input
                            :placeholder="$t('message.please_enter_input', {input:$t('message.money_amount')})"
                            :size="'medium'"
                            v-model="item.row.current_payment"
                            type="number"
                            >
                          </el-input>
                        </template>
                    </el-table-column>
                    <el-table-column
                        :label="$t('message.remainder')">
                        <template slot-scope="item">
                            {{ calculate_remainder(item.row) | formatMoney(2) }}
                        </template>
                    </el-table-column>
                </el-table>
              </el-col>
            </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import selectClient from "@/components/inventory/select-client";
import selectPaymentType from "@/components/inventory/select-paymentType";
import selectBankAccount from "@/components/inventory/select-bank-account";

export default {
  mixins: [form, drawer],
  components: {
    selectClient,
    selectPaymentType,
    selectBankAccount,
  },
  data() {
    return {
      bankAccount: {},
      drawerUnpaidDeals: false,
      selectedDealsList: [],
      loadingUnpaidDeals: false,
      paymentRemainder: 0,
      selectedClient: {}
    };
  },
  created() {},
  computed: {
      ...mapGetters({
          rules: "payments/rules",
          model: "payments/model",
          columns: "payments/columns",
          unpaidList: "deals/unpaidDealsList",
      }),
      total_remainder(){
          return _.round(parseFloat((this.total_amount - this.total_paid - ((this.form.money_amount-this.paymentRemainder)*this.form.currency_rate))), 9);
      },
      total_amount(){
          return parseFloat(this.selectedDealsList.reduce((a, b) => a + (parseFloat(b.money_amount) || 0), 0)); 
      },
      total_paid(){
          return parseFloat(this.selectedDealsList.reduce((a, b) => a + (parseFloat(b.paid_money) || 0), 0)); 
      },
      total_paying(){
          return _.round(parseFloat(this.selectedDealsList.reduce((a, b) => a + (parseFloat(b.current_payment) || 0), 0)), 9); 
      },
      remainderForClientBalance(){
          return _.round((this.form.money_amount - this.total_paying), 9)*this.form.currency_rate; 
      }
  },
  watch: {
      bankAccount: {
          handler: function(newVal, oldVal) {
              if(this.bankAccount.currency){
                this.form.currency_rate = this.bankAccount.currency.rate;
              }
          },
          deep: true,
          immediate: true
      },
      "form.money_amount": {
          handler: async function(newVal, oldVal) {
            if(newVal > this.selectedClient.balance){
              setTimeout(() => {
                this.form.money_amount = JSON.parse(JSON.stringify(this.selectedClient.balance));
              }, 100);
            }
            else if(newVal < 0){
              setTimeout(() => {
                this.form.money_amount = 0;
              }, 100);
            }
            this.paymentsByDeals(this.selectedDealsList);

          },
          deep: true,
          immediate: true
      },
      "form.currency_rate": {
          handler: function(newVal, oldVal) {
            if(this.form.money_amount > 1){
              this.paymentsByDeals(this.selectedDealsList);
            }
          },
          deep: true,
          immediate: true
      },
  },
  methods: {
      ...mapActions({
          save: "payments/paymentFromClientBalance",
          updateUnpaidList: "deals/getUnpaidDeals",
      }),
      afterOpen(){
        this.form.currency_rate = 1;
      },
      getClient(client){
        this.selectedClient = client;
        this.form.money_amount = JSON.parse(JSON.stringify(client.balance));
      },
      handleSelectionChange(val){
        this.paymentsByDeals(val);
        if(val.length === 0){
          this.selectedDealsList.map(item => item.current_payment = 0);
        }
      },
      tableRowClassName({row, rowIndex}){
        let remainder = _.round(this.calculate_remainder(row), 2);
        if(remainder < 0){
          return 'danger-row';
        }
        if(remainder === 0){
          return 'success-row';
        }
        return '';
      },
      dealSelected(selection, row){
        if(selection.some(el => el.id !== row.id)){
          this.$set(row, 'current_payment', 0);
        }
      },
      calculate_remainder(item){
        let unpaid = _.round(parseFloat(item.money_amount - item.paid_money), 9)
        if(item.current_payment && item.current_payment > 0){
          unpaid = _.round(parseFloat(unpaid - (item.current_payment*this.form.currency_rate)), 9)
        }
        return _.round(parseFloat(unpaid), 9);
      },
      checkClient(){
        if(this.form.client_id){
          this.loadingUnpaidDeals = true;
          this.updateUnpaidList({client_id: this.form.client_id})
            .then(res =>{
              this.loadingUnpaidDeals = false;
              this.selectedDealsList = JSON.parse(JSON.stringify(this.unpaidList));
              this.selectedDealsList.forEach(el => {
                this.$set(el, 'current_payment', 0);
              });
              if(this.form.money_amount > 1){
                this.paymentsByDeals(this.selectedDealsList);
              }
            }).catch(err => {
              this.loadingUnpaidDeals = false;
            });
        }else{
          this.$notify({
              title: this.$t('message.warning'),
              type: "warning",
              offset: 130,
              message: this.$t('message.please_select_input', {input: this.$t('message.client')})
          });
        }
      },
      paymentsByDeals(selectedList){
        this.paymentRemainder = this.form.money_amount;
        selectedList.forEach(element => {
          if(this.paymentRemainder > 0){
            this.$set(element, 'current_payment', 0);
            let current_payment = 0
            let total_amount_in_deal_currency = parseFloat(this.paymentRemainder * this.form.currency_rate);
            let total_unpaid = _.round(parseFloat(parseFloat(element.money_amount) - parseFloat(element.paid_money)), 9);
            if(total_amount_in_deal_currency >= total_unpaid){
              this.paymentRemainder -= _.round(parseFloat(total_unpaid * this.form.currency_rate), 9);
              current_payment = _.round(parseFloat(total_unpaid * this.form.currency_rate), 9);
            }else{
              current_payment = _.round(parseFloat(this.paymentRemainder), 9);
              this.paymentRemainder = 0;
            }
            this.$set(element, 'current_payment', current_payment);
            setTimeout(() => {
              this.$refs.multipleTable.toggleRowSelection(element, true);
            }, 100);
          }else{
            this.$set(element, 'current_payment', 0);
          }
        });
      },

      getAccount(account){
          this.bankAccount = account;
      },


      test(){
        let user_payments = [];
        let ivolved_user_ids = [];
        user_payments.forEach(element => {
          element.ivolved_users.forEach(user_id => {
            let  avarage_money = element.money/element.ivolved_users.length;

            if(ivolved_user_ids.includes(user_id)){
              let user = user_payments.find(el => el.id === user_id);
              user.money += avarage_money;
            }else{
              let user = {
                id: user_id,
                money: avarage_money
              }
              user_payments.push(user);
            }
          });
        });
      },



      afterLeave(){
        this.paymentRemainder = 0;
        this.bankAccount = {};
        this.selectedClient = {};
        this.form = {};
        this.selectedDealsList = [];
      },
      removeFromPaid(index, deal){
        this.selectedDealsList.splice(index, 1);
      },
      beforeSubmit(){
        let success = true;
        for(const deal of this.selectedDealsList){
          if(_.round((deal.money_amount - deal.current_payment*this.form.currency_rate), 9) < 0 || deal.current_payment < 0){
            success = false;
            break;
          }
        }
      
        let paymentsForDeals = this.selectedDealsList.map(el => { return {deal_id:el.id, current_payment:el.current_payment }});
        paymentsForDeals = paymentsForDeals.filter(el => el.current_payment > 0);
        this.form.paymentsForDeals = paymentsForDeals;
        if(success && (this.total_paying <= this.form.money_amount) && (this.form.money_amount > 0) && (this.form.paymentsForDeals.length > 0)){
          this.submit();
        }else if(!success){
          this.$notify({
              title: this.$t('message.error_m'),
              type: "error",  
              offset: 130,
              message: this.$t('message.incorrect_money_for_deal')
          });
        }else if(this.total_paying > this.form.money_amount){
          this.$notify({
              title: this.$t('message.error_m'),
              type: "error",  
              offset: 130,
              message: this.$t('message.insufficient_money')
          });
        }else if(this.form.money_amount <= 0){
          this.$notify({
              title: this.$t('message.error_m'),
              type: "error",  
              offset: 130,
              message: this.$t('message.enter_money_correctly')
          });
        }else if(this.form.paymentsForDeals.length === 0){
          this.$notify({
              title: this.$t('message.error_m'),
              type: "error",  
              offset: 130,
              message: this.$t('message.no_payments_to_deals')
          });
        }
      },
      submit(close = true) {
          this.$refs["form"].validate((valid) => {
              if (valid) {
                  this.loadingButton = true;
                  this.save(this.form)
                      .then((res) => {
                          if(this.remainderForClientBalance > 0){
                            this.updateFreeClients();
                          }
                          this.loadingButton = false;
                          this.$alert(res);
                          this.parent().listChanged();
                          if (close) this.close();
                      })
                      .catch((err) => {
                          this.loadingButton = false;
                          this.$alert(err);
                      });
              }
          });
      },
  },
};
</script>

<style>
.el-table .warning-row {
    background: rgb(245, 243, 142);
  }

  .el-table .success-row {
    background: #99da76;
  }
  .el-table .danger-row {
    background: #e9a5a9;
  }
  .danger-insufficient {
    color: #e6757c;
  }

  
</style>